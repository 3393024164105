
@import '../components/table';

.acao{
  display: flex;
  flex-flow: row wrap;
  &-content {
    text-align: center;
    // margin: 5px;
    // width: 15%;
  }
}
.v-data-table thead tr th,
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: $colorPrimary !important;
}

.box-table-cesta {
  // border-top: 5px solid $colorPrimary;
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;

  .topo-header {
    position: absolute;
    top: 0;
    width: 100%;
    transition: box-shadow .2s ease-in-out;
    &.fixo {
      height: 64px;
      position: fixed;
      top: 50px;
      left: 0;
      width: 1000%;
      background: #FFF !important;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
      z-index: 5;
      padding: 0 25px;
    }
  }

  .v-data-table-header-mobile {
    display: none;
  }

  .v-data-table {
    padding-top: 70px;
    position: relative;
    table {
      min-width: 1170px !important;
    }

    tbody tr:nth-of-type(even) { 
      background-color: rgba(0, 0, 0, 0.03)
    }

    tbody {
      tr {
        td {
          padding: 8px;
        }
      }
    }

    thead {
      tr {
        th {
          padding: 8px;
        }
      }
    }

    .icon-boleto {
      cursor: pointer;
    }
  }

  .table-row {
    cursor: pointer;
  }

  .flags {
    width: 15px;
    height: 15px !important;
    padding: 0;
  }

  .btn-filtro {
    width: 100%;
    margin-left: 20px;
    max-width: 100px;
    height: 40px;
    border: 1px solid #DDD;
    background: transparent;
    font-size: 14px;
    color: #6e6e6e;
    font-weight: normal;;
  }

  .btn-aprovar {
    height: 28px !important;
    margin: 0 5px;
    border-radius: 2px !important;

    &.v-btn--disabled {
      border: 1px solid #b3b3b3 !important;
      color: #b3b3b3 !important;
    }
  }

  .situacao {
    font-weight: bold;
    font-size: 12px;
  }

  .detalhe {
    cursor: pointer;
  }

  .table-header-name {
    width: 310px;
  }

  .table-header-cpf {
    width: 120px;
  }
  .table-header-id {
    width: 50px;
  }

  .table-header-cep {
    width: 90px;
  }
  .table-header-flag {
    width: 50px;
  }
  .table-header-btns {
    width: 190px;
  }
  .table-header-observacao {
    width: 100px;
  }

  .table-header-celular {
    width: 130px;
  }
}

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none;
  }
}

.v-data-table {
  table {
    // min-width: 1200px;
  }
}